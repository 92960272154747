declare var Litepicker: any;
import { pickerOptions } from "../index";
import * as utils from "../utils";
import mapboxgl, { LngLat, Marker } from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1IjoiamFkYXNpIiwiYSI6ImNrYmp4aGkwdjB0a2oyc2x0MmtwZzM0cXoifQ.GoKJy1ulhKTWKZ6dbEV0KQ";

export function init() {
  $(".api-price-wrapper").hide();
  $(".api-select-dates-wrapper").show();

  // load data from url
  const _urlParams = utils.getURLParams();
  let _search = async (arrival: Date, departure: Date) => {
    $(".api-select-dates-wrapper").hide();
    $(".api-price-wrapper .api-price-per-night").html("Loading...");
    $(".api-price-wrapper").show();
    var propertyId = $("#api-booking-form-wrapper")
      .parent()
      .attr("property-id");
    var quote = await utils.getQuote(propertyId ?? "", arrival, departure);
    if (isNaN(quote.nightlyRate)) {
      $(".api-price-wrapper").hide(), $(".api-select-dates-wrapper").show();
    } else {
      $(".api-price-wrapper .api-price-per-night").html(
        utils.dollars.format(quote.nightlyRate),
      );
      $(".api-price-wrapper .api-total-cost").html(
        utils.dollars.format(quote?.totalCost ?? 0),
      );
    }
    utils.saveURLParams({
      arrival: utils.formatDate(arrival),
      departure: utils.formatDate(departure),
    });
  };
  if (_urlParams.arrival && _urlParams.departure)
    _search(
      utils.parseUTCDate(_urlParams.arrival),
      utils.parseUTCDate(_urlParams.departure),
    );
  const propertyId = $("#api-booking-form-wrapper")
    .parent()
    .attr("property-id");
  const picker = new Litepicker({
    element: $("#api-check-in")[0],
    elementEnd: $("#api-check-out")[0],
    setup: (picker: any) => {
      picker.on("change:month", async (date: any) => {
        // lock the visible days till we have availability data from Track
        picker.setLockDays([
          [
            utils.formatDate(new Date(0)),
            utils.formatDate(new Date(Date.now() + 365 * 86400000)),
          ],
        ]);
        // get availability data
        var days = await utils.getBookingData(date["dateInstance"]);
        picker.setLockDays(days);
      });
      picker.on("before:show", async () => {
        // lock the visible days till we have availability data from Track
        picker.setLockDays([
          [
            utils.formatDate(new Date(0)),
            utils.formatDate(new Date(Date.now() + 365 * 86400000)),
          ],
        ]);
        // get availability data
        var days = await utils.getBookingData(
          picker.getDate() ?? pickerOptions.minDate,
        );
        picker.setLockDays(days);
      });
      picker.on("selected", async (checkIn: any, checkOut: any) => {
        _search(checkIn["dateInstance"], checkOut["dateInstance"]);
      });
      picker.on("clear:selection", () => {
        $(".api-price-wrapper").hide();
        $(".api-select-dates-wrapper").show();
      });
    },
    ...pickerOptions,
    minDays: utils.getMinDays(propertyId ?? ""),
    startDate: _urlParams.arrival
      ? utils.parseUTCDate(_urlParams.arrival)
      : undefined,
    endDate: _urlParams.departure
      ? utils.parseUTCDate(_urlParams.departure)
      : undefined,
  });

  $("#api-booking-form").on("submit", (e) => {
    e.preventDefault();
    e.stopPropagation();
    var propertyId = $("#api-booking-form-wrapper")
      .parent()
      .attr("property-id");

    const dateOptions = {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const startDate = picker!
      .getStartDate()!
      ["dateInstance"].toLocaleDateString("en-US", dateOptions);
    const endDate = picker!
      .getEndDate()!
      ["dateInstance"].toLocaleDateString("en-US", dateOptions);

    const url = `https://book.vacayaz.com/vrp/book/step3/?obj[Arrival]=${startDate}&obj[Departure]=${endDate}&obj[Adults]=&obj[Children]=&obj[PromoCode]=&obj[Pets]=&obj[PropID]=${propertyId}`;
    window.open(encodeURI(url));
  });
  // show booking form
  $(".api-booking-wrapper").css("display", "block");

  initMap();
}

function initMap() {
  try {
    var coords: any[] = $(".api-latlong")[0].innerText.split(",");

    if (coords?.length !== 2 || isNaN(coords[0]) || isNaN(coords[1]))
      throw Error("Failed to parse coordinates.");

    const coordinates = new LngLat(coords[1], coords[0]);

    const map = new mapboxgl.Map({
      container: "api-mapboxgl-property", // container ID
      style: "mapbox://styles/mapbox/streets-v11", // style URL
      center: coordinates,
      zoom: 12, // starting zoom
      scrollZoom: false,
    });

    // Adjust map controls for mobile
    if (window.innerWidth > 760) {
      map.addControl(new mapboxgl.NavigationControl());
    } else {
      map.dragPan.disable();
    }

    // create DOM element for the marker
    const el = document.createElement("div");
    el.id = "api-marker";
    el.innerHTML = `<svg width="56" height="122" viewBox="0 0 56 122" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M42.4568 51.9841C50.5734 47.0811 56 38.1743 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 38.1743 5.42658 47.0811 13.5433 51.9841L26.861 61.2109C27.5462 61.6856 28.4538 61.6856 29.139 61.2109L42.4568 51.9841Z" fill="#FB9549"/>
  <g clip-path="url(#clip0_441_8)">
  <path d="M28.52 16.1921C28.3751 16.0681 28.1907 16 28 16C27.8093 16 27.6249 16.0681 27.48 16.1921L16 26.0321V37.6001C16 38.2366 16.2529 38.847 16.7029 39.2971C17.153 39.7472 17.7635 40.0001 18.4 40.0001H24.8C25.0122 40.0001 25.2157 39.9158 25.3657 39.7657C25.5157 39.6157 25.6 39.4122 25.6 39.2001V34.4001C25.6 33.7635 25.8529 33.1531 26.3029 32.703C26.753 32.2529 27.3635 32.0001 28 32.0001C28.6365 32.0001 29.247 32.2529 29.6971 32.703C30.1471 33.1531 30.4 33.7635 30.4 34.4001V39.2001C30.4 39.4122 30.4843 39.6157 30.6343 39.7657C30.7843 39.9158 30.9878 40.0001 31.2 40.0001H37.6C38.2365 40.0001 38.847 39.7472 39.2971 39.2971C39.7471 38.847 40 38.2366 40 37.6001V26.0321L28.52 16.1921Z" fill="#F1F1F1"/>
  </g>
  <defs>
  <clipPath id="clip0_441_8">
  <rect width="24" height="24" fill="white" transform="translate(16 16)"/>
  </clipPath>
  </defs>
  </svg>
  `;

    // create the marker
    new Marker(el).setLngLat(coordinates).addTo(map);
  } catch (e) {
    console.warn("Property coordinates are not set correctly. Hiding map.");
    $("#api-mapboxgl-property").hide();
  }
}
