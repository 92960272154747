declare var Litepicker: any;
import { baseUrl, pickerOptions } from "../index";
import * as utils from "../utils";
import mapboxgl, { LngLat, LngLatBounds, Marker, Popup } from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1IjoiamFkYXNpIiwiYSI6ImNrYmp4aGkwdjB0a2oyc2x0MmtwZzM0cXoifQ.GoKJy1ulhKTWKZ6dbEV0KQ";

let map: mapboxgl.Map;

export function init(coords: { latitude: number; longitude: number; zoom?: number }) {
  //TODO have alec move this to webflow
  $('<div id="api-layout-toggle-mobile" class="21-subtitle">SHOW MAP</div>').appendTo('body');

  map = new mapboxgl.Map({
    container: "api-mapboxgl", // container ID
    style: "mapbox://styles/mapbox/streets-v11", // style URL
    center: [coords.longitude, coords.latitude],
    zoom: coords.zoom,
  });
  // init layout toggle
  $("#api-layout-toggle, #api-layout-toggle-mobile").on("click", function () {
    // $(".api-map-container").toggleClass("expanded");
    if ($(".api-map-container").hasClass("transition")) return;

    $(".api-map-container").addClass("transition");

    if ($(".api-map-container").hasClass("expanded")) {
      $(".api-map-container").removeClass("expanded");
      setTimeout(() => {
        // $("#api-mapboxgl").css("width", "50vw");
        $('#api-mapboxgl').removeClass('full');
        $('#api-layout-toggle-mobile').text("SHOW MAP");
        $(".api-map-container").removeClass("transition");
        map.resize();
      }, 500);
    } else {
      // $("#api-mapboxgl").css("width", "100vw");
      $('#api-mapboxgl').addClass('full');
      map.resize();
      $(".api-map-container").addClass("expanded");
      setTimeout(() => {

        $('#api-layout-toggle-mobile').text("SHOW LIST");
        map.resize();
        $(".api-map-container").removeClass("transition");
      }, 500);
    }
  });

  // inject all properties links with search data
  $(".api-listing-link").on(
    "click",
    function (e) {
      e.preventDefault();
      e.stopPropagation();
      var _searchParams = new URLSearchParams(window.location.search);
      window.location.assign(
        `${$(this).attr("href")}?${_searchParams.toString()}`
      );
    }
  );

  // collect references to properties in DOM and populate map
  $(".api-properties-container div[id*=property-]").each(function () {
    var _property = $(this).closest("[role=listitem]");
    const _id = this.id.substring(9);
    _property.addClass([_id, "_property"]);
    _property
      .on("mouseenter", function () {
        $("#api-marker-" + _id).addClass("hover");
      })
      .on("mouseleave", function () {
        $("#api-marker-" + _id).removeClass("hover");
      });
    let coords = $(this).data('coordinates')?.split(',');
    if ((coords?.length ?? 0) == 2) {
      try {
        addMapMarker(_id, new LngLat(coords[1], coords[0]));
      } catch (e: any) {
        console.warn("Couldn't add a map marker for " + _id + ". " + e.message);
      }
    }

    // get nightly rate for each property
    utils.getBaseRate(_id).then((rate) => {
      showListingRate(_id, rate);
    });
  });

  // init searchbar handler
  $("#api-property-search").on("submit", (e) => {
    e.preventDefault();
    e.stopPropagation();
    var _arrival = picker!.getStartDate()!["dateInstance"];
    var _departure = picker!.getEndDate()!["dateInstance"];
    var _minGuests = parseInt($("#api-min-guests").val() as string);
    var _minBeds = parseInt($("#api-min-beds").val() as string);
    search({
      arrival: utils.formatDate(_arrival),
      departure: utils.formatDate(_departure),
      minGuests: isNaN(_minGuests) ? 0 : _minGuests,
      minBeds: isNaN(_minBeds) ? 0 : _minBeds,
    });
  });

  // load data from url
  let _params = utils.getURLParams();
  if (_params.arrival && _params.departure) {
    search(_params);
    if (_params.minBeds) $("#api-min-beds").val(_params.minBeds);
    if (_params.minGuests) $("#api-min-guests").val(_params.minGuests);
  } else {
    $(".api-loading-indicator-wrapper").hide();
  }
  // show search bar
  $(".api-prop-search").css("display", "flex");
  const picker = new Litepicker({
    element: $("#api-check-in")[0],
    elementEnd: $("#api-check-out")[0],
    ...pickerOptions,
    startDate: _params.arrival
      ? utils.parseUTCDate(_params.arrival)
      : undefined,
    endDate: _params.departure
      ? utils.parseUTCDate(_params.departure)
      : undefined,
  });
}

export interface searchParams {
  /** date given in YYYY-MM-DD */
  arrival: string | null;
  /** date given in YYYY-MM-DD */
  departure: string | null;
  /** int from 0-99 */
  minGuests?: number;
  /** int from 0-99 */
  minBeds?: number;
}

/**
 *  Searches for available listings under the given [params].
 *  Handles hiding, loading, showing UI
 *
 */
function search(params: searchParams) {
  // hide stuff
  $(".feat-prop-wrap").hide();
  $(".all-properties-title").hide();
  $("#search-no-results").hide();
  $(".prop-results-title").hide();
  $(".api-loading-indicator-wrapper").css("display", "flex");
  $(".marker").hide();
  $("._property").hide();

  // make query
  $.ajax({
    type: "GET",
    timeout: 10000,
    url: baseUrl + "/properties/availability",
    data: {
      checkIn: params.arrival,
      checkOut: params.departure,
      guests: params.minGuests ?? 0,
      bedrooms: params.minBeds ?? 0,
    },
  })
    .fail((res) => {
      console.log("Search failed!", JSON.stringify(res));
      $(".feat-prop-wrap").show();
      $(".all-properties-title").show();
      $("._property").fadeIn();
      alert("Something went wrong. Please try again");
    })
    .done((res) => {
      const ids = res.propertyIds;
      var noResults = true;
      var bounds: LngLatBounds;
      ids.forEach((id: string) => {
        const _prop = $("._property." + id);
        if (_prop.length != 0) {
          noResults = false;
          _prop.fadeIn();
          // show map marker
          $("#api-marker-" + id).show();
          let coords = _prop.find('div[id*=property-]').data("coordinates")?.split(",");
          if ((coords?.length ?? 0) != 2) return;
          coords = [parseFloat(coords[1]), parseFloat(coords[0])];
          if (bounds) bounds.extend(coords);
          else
            bounds = new LngLatBounds([...coords, ...coords] as [
              number,
              number,
              number,
              number
            ]);

          // update rates according to the search params
          if (params.arrival && params.departure) {
            $(`._property.${id} .api-price-wrapper, #api-marker-${id} > div`).fadeOut();
            utils.getQuote(id, new Date(params.arrival), new Date(params.departure)).then((quote) => showListingRate(id, quote.nightlyRate, quote.totalCost));
          }
        }
      });

      if (noResults) {
        $("#search-no-results").css("display", "flex");
        return;
      } else {
        map.fitBounds(bounds!, { padding: 100 });
      }
      $(".prop-results-title").show();
      // scroll to top of results
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(".api-properties-container")!.offset()!.top - 91,
        },
        500
      );
    })
    .always(() => {
      $(".api-loading-indicator-wrapper").hide();
    });

  // store search data in url
  utils.saveURLParams(params);
}

function showListingRate(id: string, rate: number, totalCost?: number) {
  if (!isNaN(rate)) {
    const _r = utils.dollars.format(rate).split(".")[0];
    $(`._property.${id} .api-price-per-night, #api-marker-${id} > div`).text(_r);
    $(
      `._property.${id} .api-price-wrapper, #api-marker-${id} > div`
    ).fadeIn();
    if (totalCost) {
      $(`._property.${id} .api-total-cost`).text(totalCost);
    }
  }
}

/**
 * Creates a marker and popup for the Mapbox Map
 *
 * The marker has an id of `#api-marker-{listingId}`
 * @param listingId the listing id of the property
 */
function addMapMarker(listingId: string, coordinates: LngLat) {
  // create the popup
  const popupContent = $(`#popup-${listingId}`).closest("[role=listitem]");
  popupContent.addClass(["_popup", listingId]);
  const popup = new Popup({ offset: 25, anchor: "top" })
    .setDOMContent(popupContent[0])
    .setMaxWidth("300px")
    .on("open", () => {
      $("#api-marker-" + listingId).addClass("open viewed");
      // set the price
      $(`._popup.${listingId} .map-price`).text(
        $(`#api-marker-${listingId} > div`).text()
      );
    })
    .on("close", () => $("#api-marker-" + listingId).removeClass("open"));

  // create DOM element for the marker
  const el = document.createElement("div");
  el.id = "api-marker-" + listingId;
  el.classList.add("marker");
  const child = document.createElement("div");
  $(child).hide();
  el.append(child);

  // create the marker
  new Marker(el)
    .setLngLat(coordinates)
    .setPopup(popup) // sets a popup on this marker
    .addTo(map);
}
