import { baseUrl, pickerOptions } from "./index";
import { searchParams } from "./pages/searchProperties";
let bookingData: any = {};

/**
 * Gets the nightly rate for the current property based on a booking range
 * @returns {number} a nightly rate
 */
export async function getBaseRate(propertyId: string): Promise<number> {
  return (
    await $.ajax({
      type: "GET",
      timeout: 10000,
      url: `${baseUrl}/properties/${propertyId}/base-rate`,
    }).fail((e) => {
      console.warn("Failed to get rate for " + propertyId, e);
    })
  ).baseRate;
}

export const dollars = new Intl.NumberFormat("en-US", {
  currency: "USD",
  style: "currency",
});

/**
 * Formats Date object to YYYY-MM-DD string
 * @param {Date} date
 * @returns {string} value in YYYY-MM-DD format
 */
export function formatDate(date: Date) {
  return date.toISOString().slice(0, 10);
}

const _sedonaProperties = [
  '182263',
  '171583',
  '172812',
  '186227',
  '182874',
  '171282',
  '176177',
  '176952',
  '162150',
  '180188',
  '183019',
];

/**
* Gets the minimum night stay for a given property
* TODO: this value should be retrieved from the Track DB
*/
export function getMinDays(propertyId: string): number {
  if (_sedonaProperties.includes(propertyId)) return 3;
  return 4;
}

export function parseUTCDate(date: string) {
  const parts = date.split('-');
  try {
    return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
  } catch (e) {
    return new Date();
  }
}

/**
 * Gets booking data for 2 months starting at the first day of the month from a given date.
 * @param {Date} startDate
 * @returns an array of locked dates
 */
export async function getBookingData(startDate: Date) {
  var d = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  var endDate = new Date(d.getFullYear(), d.getMonth() + 2, 0);
  var propertyId = $("#api-booking-form-wrapper").parent().attr("property-id");
  while (d < endDate) {
    if (bookingData[formatDate(d)] == null) {
      try {
        var data = await $.ajax({
          type: "GET",
          timeout: 10000,
          url: `${baseUrl}/properties/${propertyId}/calendar`,
          data: {
            startDate: formatDate(
              new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1)
            ),
            endDate: formatDate(new Date(d.getFullYear(), d.getMonth() + 2, 0)),
          },
        });
        
        for (let i = 1; i < data.length; i++) {
          if (new Date(data[i].date) < pickerOptions.minDate) {
            bookingData[data[i].date] = false;
            continue;
          }
          bookingData[data[i].date] = data[i].status === "available";
        }

      }
      catch (e) {
        console.warn("Failed to get booking data", e);
      }
      // Check two months later as we just got data for this month and next
      d = new Date(d.getFullYear(), d.getMonth() + 2, 1);
    } else {
      // Check next month if we already have data for this month
      d = new Date(d.getFullYear(), d.getMonth() + 1, 1);
    }
  }
  var lockedDates = [];
  for (const day in bookingData) {
    if (!bookingData[day]) lockedDates.push(day);
  }
  return lockedDates;
}

/**
 * Fetches a quote for a property over a specified date range.
 * 
 * This function sends a GET request to the `/reservations/quote` endpoint with the property ID and the check-in and check-out dates as parameters. The server is expected to return a quote that includes the nightly rate and the total cost for the specified date range.
 * 
 * @param {string} propertyId - The ID of the property for which to fetch a quote.
 * @param {Date} checkIn - The check-in date for the quote.
 * @param {Date} checkOut - The check-out date for the quote.
 * 
 * @returns {Promise<{nightlyRate: number, totalCost: number}>} A promise that resolves to an object containing the nightly rate and the total cost for the specified date range. If the request fails, the promise is rejected with an error.
 * 
 * @throws {Error} If the request fails, an error is thrown with a message indicating the failure.
 */
export async function getQuote(
  propertyId: string,
  checkIn: Date,
  checkOut: Date
): Promise<{ nightlyRate: number, totalCost: number }> {
  return await $.ajax({
    type: "GET",
    timeout: 10000,
    url: `${baseUrl}/reservations/quote`,
    data: {
      propertyId: propertyId,
      checkIn: formatDate(checkIn),
      checkOut: formatDate(checkOut),
    },
  }).fail((e) => {
    console.warn("Failed to get rate", e);
  });
}

export interface urlParams extends searchParams { }

/**
 * Saves url params then calls `history.replaceState()`
 *
 * @param params Url Params interface extension of [searchParams]
 */
export function saveURLParams(params: urlParams) {
  var _sp = new URLSearchParams(window.location.search);
  if (params.arrival) _sp.set("arrival", params.arrival);
  if (params.departure) _sp.set("departure", params.departure);
  if (params.minGuests) _sp.set("minGuests", params.minGuests.toString());
  if (params.minBeds) _sp.set("minBeds", params.minBeds.toString());

  const _path = window.location.pathname;
  history.replaceState(null, "", `${_path}?${_sp.toString()}`);
}

/**
 * @param params Url Params interface extension of [searchParams]
 */
export function getURLParams(): urlParams {
  var _searchParams = new URLSearchParams(window.location.search);
  return {
    arrival: _searchParams.get("arrival") ?? null,
    departure: _searchParams.get("departure") ?? null,
    minBeds: parseInt(_searchParams.get("minBeds") ?? "0"),
    minGuests: parseInt(_searchParams.get("minGuests") ?? "0"),
  };
}
