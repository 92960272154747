import "mapbox-gl/dist/mapbox-gl.css";
import "./main.css";

import * as propertiesPage from "./pages/searchProperties";
import * as viewProperty from "./pages/viewProperty";
export const baseUrl = "https://vacay.azurewebsites.net";

export const pickerOptions = {
  plugins: ["mobilefriendly"],
  singleMode: false,
  tooltipText: {
    one: "night",
    other: "nights",
  },
  tooltipNumber: (totalDays: number) => {
    return totalDays - 1;
  },
  disallowLockDaysInRange: true,
  firstDay: 0,
  minDays: 3,
  maxDays: 36,
  minDate: new Date(),
  maxDate: getMaxDate(),
  numberOfMonths: 2,
  numberOfColumns: 2,
  format: "MMMM D",
  resetButton: true,
};

function getMaxDate(): Date {
  let maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 14);
  return maxDate;
}

// property search page
if ($(".api-prop-search").length > 0) {
  const path = window.location.pathname;
  if (path === "/sedona-properties") {
    propertiesPage.init({ latitude: 34.843099, longitude: -111.802777, zoom: 12 });
  } else if (path === "/scottsdale-properties") {
    propertiesPage.init({ latitude: 33.6, longitude: -111.9, zoom: 10 });
  } else {
    propertiesPage.init({ latitude: 34.155129, longitude: -111.855443, zoom: 8.5 });
  }
}

// view property page
if ($(".api-booking-wrapper").length > 0) {
  viewProperty.init();
}
